export class KonamiModal extends HTMLElement {
  constructor () {
    super()

    this.root = this

    this.title = this.getAttribute('title')
    this.onKeyUp = this.onKeyUp.bind(this)
    this.close = this.close.bind(this)
  }

  connectedCallback () {
    this.root.innerHTML = `
      <div class="fixed z-20 inset-0 overflow-y-auto" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div id="background" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
            <div class="block absolute top-0 right-0 pt-4 pr-4">
              <button id="closebutton" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">Close</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="sm:flex flex-col sm:items-start">
              <div class="flex items-center justify-center">
                <img src="/-/konami/fg.png" alt="Fall Guys Banner" class="relative w-11/12 -top-52 -mb-52">
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  ${this.title}
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Ce petit message de service vous est offert par notre cher fondateur SaltyKeyru'.
                    <br>
                    Cette tirade trouvée par notre Pumpk1in national est devenue l'hymne des streams Fall Guys (d'où la bannière).
                  </p>
                  <p class="text-sm text-gray-600 text-right">A nos singes sur Fall Guys</p>
                </div>
              </div>
               <audio id="keyrusong">
                <source src="/-/konami/keyru.mp3" type="audio/mpeg">
              </audio> 
            </div>
          </div>
        </div>
      </div>
    `
    this.querySelector('#closebutton').addEventListener('click', this.close)
    this.querySelector('#background').addEventListener('click', this.close)
    document.addEventListener('keyup', this.onKeyUp)

    setTimeout(() => {
      this.querySelector('#keyrusong').play()
    }, 1000)
  }

  close () {
    this.remove()
  }

  onKeyUp (e) {
    if (e.key === 'Escape') {
      this.close()
    }
  }
}
