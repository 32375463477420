export class Modal extends HTMLElement {
  constructor () {
    super()
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  connectedCallback () {
    const button = this.querySelector('button')
    const content = this.querySelector('div')

    button.addEventListener('click', this.toggleModal)
    document.addEventListener('keyup', this.onKeyUp)
    this.querySelectorAll('div[aria-hidden=true]').forEach((el, _) => {
      el.addEventListener('click', this.toggleModal)
    })

    this.button = button
    this.content = content

    if (this.getAttribute('open') === 'true') {
      this.open()
    } else {
      this.close()
    }
  }

  disconnectedCallback () {
    document.removeEventListener('keyup', this.onKeyUp)
  }

  onKeyUp (e) {
    if (e.key === 'Escape' && this.isOpen) {
      this.button.focus()
      this.close()
    }
  }

  toggleModal (e) {
    e.preventDefault()
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    this.content.removeAttribute('hidden')
    this.isOpen = true
  }

  close () {
    this.content.setAttribute('hidden', 'hidden')
    this.isOpen = false
  }
}
