export class Dropdown extends HTMLElement {
  constructor () {
    super()
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  connectedCallback () {
    const button = this.querySelector('button')
    const ul = this.querySelector('ul')

    button.addEventListener('click', this.toggleMenu)
    document.addEventListener('keyup', this.onKeyUp)

    this.button = button
    this.ul = ul
    this.close()
  }

  onKeyUp (e) {
    if (e.key === 'Escape' && this.isOpen) {
      this.button.focus()
      this.close()
    }
  }

  toggleMenu (e) {
    e.preventDefault()
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    this.ul.removeAttribute('hidden')
    this.isOpen = true
  }

  close () {
    this.ul.setAttribute('hidden', 'hidden')
    this.isOpen = false
  }
}
