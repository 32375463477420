import { DateTimePicker } from '../elements/DateTimePicker'
import { InputChoices, SelectChoices } from '../elements/SelectChoices'
import { Dropdown } from '../elements/Dropdown'
import { Modal } from '../elements/Modal'
import { KonamiModal } from '../elements/KonamiModal'
import { SlideShow } from '../elements/SlideShow'

function registerCustomElement (name, callback, option = {}) {
  try {
    customElements.define(name, callback, option)
  } catch (e) {
    console.error(e)
  }
}

registerCustomElement('datetime-picker', DateTimePicker, { extends: 'input' })
registerCustomElement('select-choices', SelectChoices, { extends: 'select' })
registerCustomElement('input-choices', InputChoices, { extends: 'input' })
registerCustomElement('dropdown-menu', Dropdown)
registerCustomElement('modal-menu', Modal)
registerCustomElement('slide-show', SlideShow)
registerCustomElement('konami-modal', KonamiModal)
