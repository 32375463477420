import Choices from 'choices.js'

export class SelectChoices extends HTMLSelectElement {
  connectedCallback () {
    if (!this.getAttribute('choicesBinded')) {
      this.setAttribute('choicesBinded', 'true')
      this.choices = new Choices(this, {
        placeholder: true,
        searchPlaceholderValue: this.getAttribute('placeholder') || 'Rechercher...',
        maxItemCount: Number(this.getAttribute('maxItemCount')) || -1,
        itemSelectText: '',
        removeItems: this.multiple,
        removeItemButton: this.multiple
      })
    }
  }

  disconnectedCallback () {
    if (this.choices) {
      this.choices.destroy()
    }
  }
}

export class InputChoices extends HTMLInputElement {
  connectedCallback () {
    if (!this.getAttribute('choicesBinded')) {
      this.setAttribute('choicesBinded', 'true')
      this.choices = new Choices(this, {
        placeholderValue: `Ajouter un ${this.placeholder}`,
        addItemText: (value) => {
          return `Appuyer sur Entrer pour ajouter <b>"${value}"</b>`
        },
        searchEnabled: false,
        removeItems: true,
        removeItemButton: true
      })
    }
  }

  disconnectedCallback () {
    if (this.choices) {
      this.choices.destroy()
    }
  }
}
