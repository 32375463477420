import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'

/**
 * @property {calendar} flatpickr
 */
export class DateTimePicker extends HTMLInputElement {
  connectedCallback () {
    this.calendar = flatpickr(this, {
      locale: French,
      enableTime: this.getAttribute('enable_time') || false,
      noCalendar: this.getAttribute('no_calendar') || false,
      time_24hr: this.getAttribute('time_24hr') || true,
      altInput: this.getAttribute('alt_format') || true,
      dateFormat: this.getAttribute('date_format') || 'Z',
      altFormat: this.getAttribute('alt_format') || 'd/m/Y H:i'
    })
  }

  disconnectedCallback () {
    this.calendar.destroy()
  }
}
