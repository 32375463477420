import { Splide } from '@splidejs/splide'
import { Grid } from '@splidejs/splide-extension-grid'

/**
 * @property {slider} Splide
 */
export class SlideShow extends HTMLElement {
  connectedCallback () {
    this.slider = new Splide(this, {
      type: 'loop',
      drag: 'free',
      arrows: false,
      pagination: false,
      padding: '0.5em',
      grid: {
        dimensions: [[4, 2]]
      },
      autoplay: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      lazyLoad: 'nearby',
      perPage: 10,
      breakpoints: {
        640: {
          perPage: 4
        },
        1024: {
          perPage: 6
        },
        1280: {
          perPage: 8
        },
      }
    })

    this.slider.mount({ Grid })
  }

  disconnectedCallback () {
    this.slider.destroy()
  }
}
